import { Component } from '@angular/core';
import { TopService } from '../../services/top.service';

@Component({
  selector: 'app-gitex-banner',
  standalone: true,
  imports: [],
  templateUrl: './gitex-banner.component.html',
  styleUrl: './gitex-banner.component.scss'
})
export class GitexBannerComponent {

  constructor(
    public topService: TopService
  ) { }

}
