import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { ItemDetailMobileComponent } from './item-detail-mobile/item-detail-mobile.component';
import { ItemDetailDesktopComponent } from './item-detail-desktop/item-detail-desktop.component';
import {NgClickOutsideDirective} from 'ng-click-outside2';
import { GradingGuide2Component } from './grading-guide2/grading-guide2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from '../user/forgot-password/forgot-password.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingPageSliderComponent } from './landing-page/landing-page-slider/landing-page-slider.component';
import { LandingPageTrendingBoxesComponent } from './landing-page/landing-page-trending-boxes/landing-page-trending-boxes.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PastPurchaseTitleComponent } from '../shared/past-purchase-title/past-purchase-title.component';
import { ItemQuickSortBaseComponent } from './item-quick-sort-base/item-quick-sort-base.component';
import { ItemFilterComponent } from './item-filter/item-filter.component';
import { AuctionSliderComponent } from '../buyer/auctions/auction-slider/auction-slider.component';
import { RouterModule } from '@angular/router';
import { CountdownComponent } from './countdown/countdown.component';
import { AuctionConfirmComponent } from './auction-confirm/auction-confirm.component';
import { AuctionBidNotifComponent } from './auction-bid-notification/auction-bid-notif.component';
import { ManufacturersFilterPipe } from './pipes/manufacturers-filter.pipe';
import { ManufacturerSortbyPipe } from './pipes/manufacturer-sortby.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { WaitlistTooltipComponent } from './waitlist-tooltip/waitlist-tooltip.component';
import { LoginPopupComponent } from '../buyer/guest-login/login-popup/login-popup.component';
import { BottomNavMobileComponent } from './bottom-nav-mobile/bottom-nav-mobile.component';
import { BottomNavMobileExtDropdown } from './bottom-nav-mobile/bottom-nav-mobile-ext-dropdown';
import { ListingViewItemMobileComponent } from './listing-view-item/listing-view-item-mobile/listing-view-item-mobile.component';
import { WhiteLogosPipe } from './pipes/white-logos.pipe';
import { ItemFilterMobileComponent } from './item-filter/item-filter-mobile/item-filter-mobile.component';
import { ItemsBreadcrumbsMobileComponent
} from '../buyer/catalog/items-breadcrumbs/items-breadcrumbs-mobile/items-breadcrumbs-mobile.component';
import { PastPurchaseTitleMobileComponent } from './past-purchase-title/past-purchase-title-mobile/past-purchase-title-mobile.component';
// import { BlackCategoriesImagesPipe } from './pipes/black-categories-images.pipe';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { LandinPageUserFiltersBannerComponent } from './landing-page/landin-page-user-filters-banner/landin-page-user-filters-banner.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { UserFiltersFaqComponent } from '../buyer/user-filters/user-filters-faq/user-filters-faq.component';
import { UserFiltersModalComponent } from '../buyer/user-filters/user-filters-modal/user-filters-modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';

import { CustomSelectComponent } from './ui-components/custom-select/custom-select.component';
import { LoaderComponent } from './ui-components/loader/loader.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CardBannerComponent } from './landing-page/card-banner/card-banner.component';
import { DoorbusterDealsBannerComponent } from './landing-page/doorbuster-deals-banner/doorbuster-deals-banner.component';
import { CurrentDealPipe } from './pipes/current-deal.pipe';
import { ItemsPreloaderComponent } from './ui-components/items-preloader/items-preloader.component';
import { ItemsPreloaderMobileComponent } from './ui-components/items-preloader/items-preloader-mobile/items-preloader-mobile.component';
import { BiddeskBannerComponent } from './landing-page/biddesk-banner/biddesk-banner.component';
import { GitexBannerComponent } from './landing-page/gitex-banner/gitex-banner.component';
import { BiddeskPopupComponent } from './landing-page/biddesk-banner/biddesk-popup/biddesk-popup.component';
import { AddToCartModalComponent } from './ui-components/add-to-cart-modal/add-to-cart-modal.component';
import { VendorItemsPreloaderComponent } from './ui-components/vendor-items-preloader/vendor-items-preloader.component';
import { PurchaseItemPreloaderComponent } from './ui-components/vendor-items-preloader/purchase-item-preloader/purchase-item-preloader.component';
import { PoItemPreloaderComponent } from './ui-components/vendor-items-preloader/po-item-preloader/po-item-preloader.component';
import { DetailedItemPreloaderComponent } from './ui-components/vendor-items-preloader/detailed-item-preloader/detailed-item-preloader.component';
import { ListingItemPreloaderComponent } from './ui-components/vendor-items-preloader/listing-item-preloader/listing-item-preloader.component';
import { NotificationItemPreloaderComponent } from './ui-components/vendor-items-preloader/notification-item-preloader/notification-item-preloader.component';
import { DashboardNotificationPreloaderComponent } from './ui-components/vendor-items-preloader/dashboard-notification-preloader/dashboard-notification-preloader.component';
import { DashboardJustSoldPreloaderComponent } from './ui-components/vendor-items-preloader/dashboard-just-sold-preloader/dashboard-just-sold-preloader.component';
import { ErrorModalComponent } from './ui-components/error-modal/error-modal.component';
import { RestrictionNoticeComponent } from './ui-components/restriction-notice/restriction-notice.component';
import { MakeOfferComponent } from './item-detail-desktop/make-offer/make-offer.component';
import { TimeTodayPipe } from './pipes/time-today.pipe';
import { ShortLabelPipe } from './pipes/short-label.pipe';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { OffersBannerComponent } from './landing-page/offers-banner/offers-banner.component';
import { OffersPopupComponent } from './landing-page/offers-banner/offers-popup/offers-popup.component';
import { UtcToNowOffersPipe } from './pipes/utc-to-now-offers.pipe';
import { GenericCountdownComponent } from './generic-countdown/generic-countdown.component';
import { MobileComingSoonComponent } from './ui-components/mobile-coming-soon/mobile-coming-soon.component';
import { CustomOfferCurrencyPipe } from './pipes/offer-currency.pipe';
import { OfferCancellationWarningComponent } from '../user/offers/offer-cancellation-warning/offer-cancellation-warning.component';
import { MobileNotificationsModalComponent } from '../user/mobile-notifications-modal/mobile-notifications-modal.component';



export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        CarouselModule,
        RouterModule,
        VirtualScrollerModule,
        // Ng5SliderModule,
        NgxSliderModule,
        NgxMaskModule.forRoot(options),
        NgClickOutsideDirective,
        OnlyDigitsDirective,
        CustomCurrencyPipe,
        ItemDetailMobileComponent,
        ItemDetailDesktopComponent,
        GradingGuide2Component,
        PastPurchaseTitleComponent,
        ItemQuickSortBaseComponent,
        DropdownDirective,
        LandingPageComponent,
        LandingPageSliderComponent,
        LandingPageTrendingBoxesComponent,
        ItemFilterComponent,
        AuctionSliderComponent,
        CountdownComponent,
        AuctionConfirmComponent,
        AuctionBidNotifComponent,
        ManufacturersFilterPipe,
        ManufacturerSortbyPipe,
        SafeHtmlPipe,
        WaitlistTooltipComponent,
        BottomNavMobileComponent,
        BottomNavMobileExtDropdown,
        ListingViewItemMobileComponent,
        WhiteLogosPipe,
        ItemFilterMobileComponent,
        LoginPopupComponent,
        ItemsBreadcrumbsMobileComponent,
        PastPurchaseTitleMobileComponent,
        ForgotPasswordComponent,
        LandinPageUserFiltersBannerComponent,
        UserFiltersFaqComponent,
        UserFiltersModalComponent,
        DropdownComponent,
        CustomSelectComponent,
        LoaderComponent,
        CardBannerComponent,
        DoorbusterDealsBannerComponent,
        CurrentDealPipe,
        ItemsPreloaderComponent,
        ItemsPreloaderMobileComponent,
        BiddeskBannerComponent,
        GitexBannerComponent,
        BiddeskPopupComponent,
        AddToCartModalComponent,
        VendorItemsPreloaderComponent,
        PurchaseItemPreloaderComponent,
        PoItemPreloaderComponent,
        DetailedItemPreloaderComponent,
        ListingItemPreloaderComponent,
        NotificationItemPreloaderComponent,
        DashboardNotificationPreloaderComponent,
        DashboardJustSoldPreloaderComponent,
        ErrorModalComponent,
        RestrictionNoticeComponent,
        MakeOfferComponent,
        TimeTodayPipe,
        ShortLabelPipe,
        ConfirmationPopupComponent,
        OffersBannerComponent,
        OffersPopupComponent,
        UtcToNowOffersPipe,
        GenericCountdownComponent,
        MobileComingSoonComponent,
        CustomOfferCurrencyPipe,
        OfferCancellationWarningComponent,
        MobileNotificationsModalComponent
    ],
    providers: [
        CustomCurrencyPipe,
        CustomOfferCurrencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        }
    ],
    exports: [
        OnlyDigitsDirective,
        CustomCurrencyPipe,
        ItemDetailDesktopComponent,
        ItemDetailMobileComponent,
        GradingGuide2Component,
        InfiniteScrollModule,
        PastPurchaseTitleComponent,
        ItemQuickSortBaseComponent,
        DropdownDirective,
        LandingPageComponent,
        LandingPageSliderComponent,
        LandingPageTrendingBoxesComponent,
        ItemFilterComponent,
        AuctionSliderComponent,
        CountdownComponent,
        AuctionConfirmComponent,
        AuctionBidNotifComponent,
        ManufacturersFilterPipe,
        ManufacturerSortbyPipe,
        SafeHtmlPipe,
        WaitlistTooltipComponent,
        BottomNavMobileComponent,
        BottomNavMobileExtDropdown,
        ListingViewItemMobileComponent,
        WhiteLogosPipe,
        ItemFilterMobileComponent,
        LoginPopupComponent,
        ItemsBreadcrumbsMobileComponent,
        VirtualScrollerModule,
        ForgotPasswordComponent,
        // Ng5SliderModule,
        NgxSliderModule,
        UserFiltersFaqComponent,
        UserFiltersModalComponent,
        DropdownComponent,
        CustomSelectComponent,
        LoaderComponent,
        NgxMaskModule,
        CurrentDealPipe,
        ItemsPreloaderComponent,
        ItemsPreloaderMobileComponent,
        AddToCartModalComponent,
        VendorItemsPreloaderComponent,
        DashboardNotificationPreloaderComponent,
        DashboardJustSoldPreloaderComponent,
        ErrorModalComponent,
        RestrictionNoticeComponent,
        MakeOfferComponent,
        TimeTodayPipe,
        ShortLabelPipe,
        ConfirmationPopupComponent,
        UtcToNowOffersPipe,
        GenericCountdownComponent,
        MobileComingSoonComponent,
        CustomOfferCurrencyPipe,
        OfferCancellationWarningComponent,
        MobileNotificationsModalComponent,
        NgClickOutsideDirective
    ]
})
export class SharedModule { }
