<div class="landing" [class.mobile]="topService.isMobile">
  <app-gitex-banner class="landing-gitex-banner"></app-gitex-banner>
  <app-offers-banner class="landing-offers-banner"></app-offers-banner>
  <app-doorbuster-deals-banner class="landing-doorbuster-banner"></app-doorbuster-deals-banner>
  <app-landin-page-user-filters-banner class="landing-ss-banner"></app-landin-page-user-filters-banner>
  <div class="landing-cc-banner" *ngIf="isBuyerPortal() && userService.isCreditCardAllowed && userService.isCreditCardListEmpty === 0">
    <app-card-banner></app-card-banner>
  </div>
  <app-landing-page-slider></app-landing-page-slider>

  <div class="landing-trending-blocks">
  	<app-landing-page-trending-boxes></app-landing-page-trending-boxes>
  </div>
  <div class="loader" *ngIf="topService.isMobile && !auctionsService.itemsReady"></div>
  <div class="loader" *ngIf="!topService.isMobile && (!auctionsService.itemsReady || userService.loadingCC > 0 || searchService.searchLoading > 0)"></div>
  <div class="landing-black-slider" [class.hide]="!auctionsService.itemsReady && topService.isMobile">
    <app-auction-slider></app-auction-slider>
  </div>
</div>
