import { Component, OnInit, OnDestroy } from '@angular/core';
import { TopService } from '../services/top.service';
import { SharedService } from '../shared.service';
import { ItemsService } from '../services/items.service';
import { OutletsService } from '../../buyer/outlets/outlets.service';
import { GuestService } from '../services/guest.service';
import { AuthService } from '../../auth/auth.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../user/user.service';
import { AuctionsService } from '../../buyer/auctions/auctions.service';
import { CreditCardService } from '../../buyer/credit-cards/credit-card.service';
import { GlobalSearchService } from '../../services/search.service';
import { AuctionSliderComponent } from '../../buyer/auctions/auction-slider/auction-slider.component';
import { LandingPageTrendingBoxesComponent } from './landing-page-trending-boxes/landing-page-trending-boxes.component';
import { LandingPageSliderComponent } from './landing-page-slider/landing-page-slider.component';
import { CardBannerComponent } from './card-banner/card-banner.component';
import { NgIf } from '@angular/common';
import { LandinPageUserFiltersBannerComponent } from './landin-page-user-filters-banner/landin-page-user-filters-banner.component';
import { DoorbusterDealsBannerComponent } from './doorbuster-deals-banner/doorbuster-deals-banner.component';
import { OffersBannerComponent } from './offers-banner/offers-banner.component';
import { GitexBannerComponent } from './gitex-banner/gitex-banner.component';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    standalone: true,
    imports: [OffersBannerComponent, DoorbusterDealsBannerComponent,GitexBannerComponent, LandinPageUserFiltersBannerComponent, NgIf, CardBannerComponent, LandingPageSliderComponent, LandingPageTrendingBoxesComponent, AuctionSliderComponent]
})
export class LandingPageComponent implements OnInit, OnDestroy {

  constructor(
    public topService: TopService,
    public sharedService: SharedService,
    private itemsService: ItemsService,
    public outletsService: OutletsService,
    public auctionsService: AuctionsService,
    public guestService: GuestService,
    public authService: AuthService,
    public sessionService: SessionService,
    public userService: UserService,
    public searchService: GlobalSearchService,
  ) { }

  ngOnInit() {
    this.topService.loading = false;
    this.userService.isLogginPopupOpen = false;
    this.itemsService.selectedTopAttribute = '';
    this.itemsService.isLandingPage = true;

    if (!this.itemsService.manufacturersData.length) {
      this.itemsService.getManufactures()
        .subscribe(
          manufacturers => {
            // this.itemsService.initializeManufacturerData(manufacturers);
            this.outletsService.currentManufacturers = manufacturers;
          },
          err => {
            console.error('Error getting all manufacturers', err);
          });
    }

    if (this.sessionService.userEmail) {
      this.guestService.getGuestEmail(this.sessionService.userEmail).subscribe(
        (result) => {
          this.userService.leadProfile = result;
        },
        (error) => {
          // alert('Error occured on get guest email translation.');
          console.log('error occured on get guest email translation.');
        }
      );
    }

  }

  isBuyerPortal(): boolean {
    return ['BUYER'].includes(sessionStorage.getItem('user_role'));
  }

  ngOnDestroy() {
    this.itemsService.isLandingPage = false;
  }

}
