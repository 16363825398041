import { DynamicComponentManagerService } from './../../shared/services/dynamic-components/dynamic-component-manager.service';
import { IOfferEventMessage } from './../../shared/interfaces/IOfferNotificationMessage';
import { TopService } from './../../shared/services/top.service';
import { PubSubService } from './../../core/pubsub.service';
import { SharedSource } from './../../core/shared-source';
import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild, ViewContainerRef, ComponentFactory, ChangeDetectorRef, ComponentRef, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { OfferChatMessageComponent } from './offer-chat-message/offer-chat-message.component';
import { OfferService } from './offer.service';
import * as models from './../../shared/interfaces/model';
import * as enums from './../../shared/enums/enum';
import { Observable, Subscription, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location, NgIf, NgStyle } from '@angular/common';
import { SessionService } from './../../services/session.service';
import { ItemsService } from './../../shared/services/items.service';
import { IItem } from './../../buyer/interfaces/IItem';
import { map, switchMap } from 'rxjs/operators';
import { VendorService } from './../../vendor/vendor.service';
import { IVendorItemInfo } from './../../vendor/interface/IVendorItemInfo';
import * as momenttz from 'moment-timezone';
import { SharedService } from './../../shared/shared.service';
import { PercentPipe } from '@angular/common';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { DynamicComponent } from './../../shared/services/dynamic-components/dynamic-component';
import { browserRefresh } from './../../app.component';
import { OrdersService } from './../../buyer/order-history/orders/orders.service';
import { OfferErrorCodeMap } from './../../shared/classes/offer-error-code-map';
import { ConfirmationPopupComponent } from '../../shared/confirmation-popup/confirmation-popup.component';
import { OfferListComponent } from './offerlist/offer-list.component';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss', '../../shared/confirm-modal/confirm-modal.component.scss'],
    standalone: true,
    imports: [NgIf, OfferListComponent, NgStyle, ConfirmationPopupComponent]
})
export class OffersComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  totalOffersByStatusResponse: models.TotalOfferByStatusResponse[] = [];
  OfferEventTypes = enums.OfferEventTypes;
  isOfferListLoading = false;
  offersByStatusRequest: models.OffersByStatusRequest;
  defaultTitleDropdown: string;
  waitingForOfferSummaryToInitialize = false;
  paramOfferId = null;
  userRole = '';
  imageBaseUrl = environment.imageBaseUrl;
  @ViewChild('eventMessages', { read: ViewContainerRef }) eventMessageContainer: ViewContainerRef;
  @ViewChild('dynamicMessages', { read: ViewContainerRef }) dynamicMessageContainer: ViewContainerRef;
  @ViewChild(OfferChatMessageComponent, { static: true }) offerChatMessageComponent: OfferChatMessageComponent;
  eventMessages = [];
  isBuyer: boolean;
  isVendor: boolean;
  isGuest: boolean;
  isSuperOrSaleNoImpersonated: boolean;
  isLandingInSelectedOffer = 0;
  isSelectedOfferLoading = false;
  isSpecialRole: boolean;
  localBrowserRefresh = browserRefresh;
  previousOfferEvent: models.OfferEvent;
  byPassDelay = false;

  constructor(
    public offerService: OfferService,
    protected pubSubService: PubSubService,
    public topService: TopService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private sessionService: SessionService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private itemsService: ItemsService,
    private vendorService: VendorService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private dynamicComponentManagerService: DynamicComponentManagerService,
    private ordersService: OrdersService) {
    this.offerService.selectedOffer = this.offerService.getEmptyOfferState();
    this.offerService.isBuyer = ['SUPER', 'SALES', 'BUYER'].includes(sessionStorage.getItem('user_role'));
    this.offerService.isSales = ['SALES'].includes(sessionStorage.getItem('user_role'));
    this.offerService.isVendorMgr = ['VENDORMGR'].includes(sessionStorage.getItem('user_role'));
  }

  ngOnInit(): void {
    this.subscribeToPubService();

    this.paramOfferId = this.route.snapshot.data.id;
    if (this.paramOfferId) {
      this.subscriptions.push(this.offerService.getOfferById(this.paramOfferId)
        .subscribe(
          (offer: models.Offer) => {
            this.offerService.selectedOffer = offer;
            this.initialize();

            this.subscriptions.push(
              this.getOfferItemDetail(this.offerService.selectedOffer.vendorItemId)
                .subscribe((item) => {
                  this.offerService.selectedOfferItem.availability = item.availability;
                  this.offerService.selectedOfferItem.listPrice = item.listPrice;
                  this.offerService.selectedOfferItem.prices = item.prices;
                })
            );
          },
          (err) => { console.log('Error trying to get offer by id ', err); }
        ))
    } else {
      this.initialize();
    }

  }

  offerChatLoaded() {
    const path = this.location.path();
    const segments = path.split('/');
    const offerIndex = segments.findIndex(seg => seg === 'offers');
    if (offerIndex !== -1 && segments.length > offerIndex + 1) {
      const offerId = segments[offerIndex + 1];
      return !!offerId;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.clearComponentContainers();
    this.offerService.selectedOffer = this.offerService.getEmptyOfferState();
    this.offerService.offersInitialized = false;
    this.offerService.selectedOfferItem = this.offerService.getEmptyOfferItemState();
    this.offerService.disableCounterofferFormActions = false;
    this.offerService.offerByStatusResponse = null;
    this.offerService.priceOptions = [];
  }

  clearComponentContainers() {
    this.dynamicComponentManagerService.removeAllDynamicComponents();
    for (let i = 0; i < this.eventMessages.length; i++)
      this.eventMessages.pop();
  }

  initialize() {
    this.isGuest = this.userRole === 'GUEST' ? true : false;
    if (this.isGuest || this.offerService.offersInitialized)
      return;

    this.userRole = this.sessionService.userRole;
    this.isBuyer = ['SUPER', 'SALES', 'BUYER'].includes(this.userRole);
    this.isVendor = ['SELLER', 'VENDORMGR', 'ADMIN'].includes(this.userRole);
    this.isSuperOrSaleNoImpersonated = ['SUPER', 'SALES'].includes(this.userRole) && !sessionStorage.getItem('isCustomerSelected');
    this.isSpecialRole = ['SUPER', 'ADMIN'].includes(this.userRole);
    this.offerService.selectedOfferItem = this.offerService.getEmptyOfferItemState();
    this.offersByStatusRequest = {
      pageSize: environment.offerListPageSize,
      pageNumber: 1,
      status: enums.FullOfferStatusEnum.All,
    }

    if (this.offerService.offersSummary) {
      if (this.isBuyer && !this.offerService.offersSummary.hasMadeOffers)
        return;
      this.offerService.offersInitialized = true;
      this.getTotalOffersByStatus(true);
    } else {
      this.waitingForOfferSummaryToInitialize = true;
    }
  }

  subscribeToPubService() {
    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(
        (sharedValue) => {
          switch (sharedValue.name) {
            case SharedSource.handleNewOfferEventReceived:
              this.handleNewOfferEventReceived(sharedValue.data);
              break;

            case SharedSource.sessionSwitchedAndSummaryReady:
            case SharedSource.changeCustomerVendor:
              this.paramOfferId = null;
              this.initialize();
              break;

            case SharedSource.offerSummary:
              if (this.waitingForOfferSummaryToInitialize) {
                this.waitingForOfferSummaryToInitialize = false;
                this.initialize();
              }
              break;

            case SharedSource.offerFeatureSwitched:
              if (this.offerService.selectedOffer.id) {
                this.selectOffer(this.offerService.selectedOffer.id);
              }
              break;

            case SharedSource.offerAccepted:
              this.acceptOffer();
              break;

            case SharedSource.previousOfferAccepted:
              this.acceptPreviousOffer(sharedValue.data);
              break;


            case SharedSource.offerRejected:
              this.rejectOffer(sharedValue.data);
              break;

            case SharedSource.offerSeen:
              this.markOfferAsSeen(sharedValue.data.offerId);
              break;

            case SharedSource.offerCanceled:
              this.cancelOffer();
              break;

            default:
              break;
          }
        }
      ));
  }

  selectOffer(id: string, offerLoaded = false) {
    if (this.isSelectedOfferLoading) {
      return;
    }

    if (!this.topService.isMobile && !offerLoaded) {
      this.offerService.selectedOffer = this.offerService.getEmptyOfferState();
      this.offerService.selectedOfferItem = this.offerService.getEmptyOfferItemState();
    }

    if (offerLoaded) {
      const instanceId = sessionStorage.getItem('sessionId');
      this.seenOffer(this.offerService.selectedOffer.id, instanceId);

      if (this.eventMessages.length > 0) {
        this.clearComponentContainers();
      }

      const offerByStatus = this.offerService.offerByStatusResponse.offerDataResponses.find(obs => obs.offerId === this.offerService.selectedOffer.id);
      this.offerService.selectedOfferItem.itemCode = offerByStatus.itemCode;
      this.offerService.selectedOfferItem.manufacturerLogoUrl = offerByStatus.manufacturerLogoUrl;
      this.offerService.selectedOfferItem.description = this.offerService.selectedOffer.description;

      this.fillOfferChat(this.offerService.selectedOffer.offerEvents);
      this.offerService.disableCounterofferFormActions = false;
      return;
    }


    this.topService.loading = true;
    this.isSelectedOfferLoading = true;
    this.subscriptions.push(
      this.offerService.getOfferById(id)
        .subscribe(
          (offer: models.Offer) => {
            this.topService.loading = false;
            this.offerService.selectedOffer = offer;
            this.subscriptions.push(
              this.getOfferItemDetail(this.offerService.selectedOffer.vendorItemId)
                .subscribe((item) => {
                  this.offerService.selectedOfferItem.availability = item.availability;
                  this.offerService.selectedOfferItem.listPrice = item.listPrice;
                  this.offerService.selectedOfferItem.prices = item.prices;
                })
            );
            const instanceId = sessionStorage.getItem('sessionId');
            this.seenOffer(this.offerService.selectedOffer.id, instanceId);

            if (this.eventMessages.length > 0) {
              this.clearComponentContainers();
            }

            const offerByStatus = this.offerService.offerByStatusResponse.offerDataResponses.find(obs => obs.offerId === offer.id);
            this.offerService.selectedOfferItem.itemCode = offerByStatus.itemCode;
            this.offerService.selectedOfferItem.manufacturerLogoUrl = offerByStatus.manufacturerLogoUrl;
            this.offerService.selectedOfferItem.description = offer.description;

            this.fillOfferChat(this.offerService.selectedOffer.offerEvents);
            this.isSelectedOfferLoading = false;
            this.offerService.disableCounterofferFormActions = false;
          },
          (err) => {
            console.log('Error trying to get offer by id ', err);
            this.topService.loading = false;
            this.isSelectedOfferLoading = false;
            this.offerService.disableCounterofferFormActions = false;
          }
        )
    )
  }

  getOfferItemDetail(itemId: string): Observable<models.OfferItemDetail> {
    if (this.isBuyer) {
      return this.itemsService.getItem(itemId).pipe(
        switchMap((i: IItem) => {
          return of(
            {
              availability: i.inventory.availableToSell,
              listPrice: i.unitPrice,
              prices: i.prices
            } as models.OfferItemDetail
          )
        })
      );
    }
    if (this.isVendor) {
      return this.vendorService.getVendorItemById([itemId]).pipe(
        switchMap((i: any) => {
          return of(
            {
              availability: i.items[0].inventoryInfo.currentInventory.availableToSell,
              listPrice: i.items[0].cost,
              prices: i.items[0].prices
            } as models.OfferItemDetail
          )
        })
      );
    }
  }

  updateOrAddOfferToList(id: string) {
    this.topService.loading = true;
    this.subscriptions.push(
      this.offerService.getOfferByIdSummary(id)
        .subscribe(
          (offer: models.OfferByStatusData) => {
            this.topService.loading = false;
            const index = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId == id);
            if (index >= 0) {
              this.offerService.offerByStatusResponse.offerDataResponses.splice(index, 1);
            }
            if (offer.offerStatus === this.offersByStatusRequest.status || this.offersByStatusRequest.status === enums.FullOfferStatusEnum.All) {
              this.offerService.offerByStatusResponse.offerDataResponses.unshift(offer);
            }

            if (!this.offerService.offerByStatusResponse.offerDataResponses.length) {
              this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);
            }
            this.cdr.detectChanges();
          },
          (err) => {
            console.log('Error trying to get offer by id summary', err);
            this.topService.loading = false;
          }
        )
    )
  }

  onOffersByStatusRequest(request: models.OffersByStatusRequest) {
    if (request.resetSelectedOffer) {
      this.offerService.selectedOffer = this.offerService.getEmptyOfferState();
      this.offerService.selectedOfferItem = this.offerService.getEmptyOfferItemState();
      this.isBuyer ? this.location.replaceState('user/offers') : this.location.replaceState('vendor/user/offers');
    }
    this.offersByStatusRequest = request;
    this.getOffersByStatus(request, false);
  }

  getOffersByStatus(request: models.OffersByStatusRequest, selectOffer = false, initializing = false) {
    if (this.isBuyer &&
      (!this.offerService.offersSummary?.hasMadeOffers || (this.isSuperOrSaleNoImpersonated))) {
      return;
    }
    this.isOfferListLoading = true;
    this.subscriptions.push(
      this.offerService.getOffersByStatus(request).subscribe(
        (response: models.OffersByStatusResponse) => {
          if (!this.offerService.offerByStatusResponse)
            this.offerService.offerByStatusResponse = response
          else {
            this.offerService.offerByStatusResponse.pagination = response.pagination;

            if (this.offerService.offerByStatusResponse.pagination.currentPage == 1) {
              this.offerService.offerByStatusResponse.offerDataResponses = response.offerDataResponses;
            }
            else
              this.offerService.offerByStatusResponse.offerDataResponses.push(...response.offerDataResponses);
          }

          if (this.localBrowserRefresh && this.paramOfferId) {
            this.localBrowserRefresh = false;
            const offerByStatus = this.offerService.offerByStatusResponse.offerDataResponses.find(obs => obs.offerId === this.paramOfferId);
            this.offerService.selectedOfferItem.itemCode = offerByStatus.itemCode;
            this.offerService.selectedOfferItem.manufacturerLogoUrl = offerByStatus.manufacturerLogoUrl;
          }
          this.isOfferListLoading = false;

          if (selectOffer) {
            this.selectOffer(this.paramOfferId, initializing);
          } else {
            this.paramOfferId = null;
          }
          setTimeout(() => {
            this.pubSubService.sharedSubject.next({ name: SharedSource.updateOffersByStatusDropdown, data: request.status });
          }, 0);
        },
        (error) => {
          console.log(error, 'Error Get Offer by Status')
        }));
  }

  seenOffer(offerId: string, instanceId: string, byPass: boolean = false) {
    const index = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId === offerId);
    if (!this.offerService.offerByStatusResponse.offerDataResponses[index].unseenEventsCount && !byPass) {
      return;
    }

    const requestPayload: models.seenOfferRequest = {
      offerId: offerId,
      instanceId: instanceId
    }

    this.subscriptions.push(
      this.offerService.seenOffer(requestPayload).subscribe(
        (response: boolean) => {
          this.offerService.offerByStatusResponse.offerDataResponses[index].unseenEventsCount = 0;
          this.offerService.getCountersForUserAndBp();
        },
        (error) => { console.log(error, ' Error Seen Offer') })
    );
  }

  getTotalOffersByStatus(initializing = false) {
    if (this.isBuyer && (!this.offerService.offersSummary?.hasMadeOffers || this.isSuperOrSaleNoImpersonated)) {
      return;
    }

    this.subscriptions.push(this.offerService.getTotalOffersByStatus()
      .subscribe(
        (totals: models.TotalOfferByStatusResponse[]) => {
          this.totalOffersByStatusResponse = [];
          let allOfferCounter = 0;
          totals.forEach((t) => {
            this.totalOffersByStatusResponse.push({ displayName: enums.FullOfferStatusEnum[t.status] + ' Offers', status: t.status, total: t.total });
            allOfferCounter += t.total;
          });
          var pendingOption = this.totalOffersByStatusResponse.find(x => x.status === enums.FullOfferStatusEnum.Pending);
          var pendingOfferCounter = pendingOption ? pendingOption.total : 0;

          this.totalOffersByStatusResponse.unshift({ displayName: 'All Offers', status: enums.FullOfferStatusEnum.All, total: allOfferCounter });
          const totalLabel = !allOfferCounter ? '' : ` (${allOfferCounter})`;
          if (initializing) {
            if (this.paramOfferId) { // redireccionar al estado de la oferta
              var option = this.totalOffersByStatusResponse.find(x => x.status === this.offerService.selectedOffer.status);
              this.defaultTitleDropdown = `${enums.FullOfferStatusEnum[option.status]} Offers (${option.total})`;
              this.changeOfferStatusFilter(this.offerService.selectedOffer.status, this.paramOfferId, initializing);
            } else {
              if (pendingOfferCounter === 0) {
                this.defaultTitleDropdown = `All offers${totalLabel}`;
                this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null, initializing);
              }
              else {
                this.defaultTitleDropdown = `Pending Offers (${pendingOfferCounter})`;
                this.changeOfferStatusFilter(enums.FullOfferStatusEnum.Pending, null, initializing);
              }
            }
          }
          this.cdr.detectChanges()
        }));
  }

  handleNewOfferEventReceived(offerEvent: IOfferEventMessage): void {
    const instanceId = sessionStorage.getItem('sessionId');
    this.subscriptions.push(
      this.offerService.getOffersSummary(false).subscribe(
        () => {
          if (this.offerService.selectedOffer?.id === offerEvent.offerId) {
            if (offerEvent.instanceId !== instanceId) {
              //if it is an accept or reject event and pending status is selected in dropdown filter
              if ([
                enums.OfferEventTypes.BuyerAcceptedOffer,
                enums.OfferEventTypes.VendorAcceptedOffer,
                enums.OfferEventTypes.BuyerRejectedOffer,
                enums.OfferEventTypes.VendorRejectedOffer,
                enums.OfferEventTypes.BuyerCancelledOffer,
                enums.OfferEventTypes.VendorCancelledOffer
              ].includes(offerEvent.eventType)
                && this.offerService.selectedOfferStatusFilter?.status === enums.FullOfferStatusEnum.Pending
              ) {
                this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, offerEvent.offerId);
                return;
              }

              this.newMessagesReceived(offerEvent.offerId, null);
              const index = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId === offerEvent.offerId);
              var offer = this.offerService.offerByStatusResponse.offerDataResponses[index];
              if (index >= 0) {
                this.offerService.offerByStatusResponse.offerDataResponses.splice(index, 1);

                if ([enums.OfferEventTypes.BuyersOfferExpired, enums.OfferEventTypes.VendorsOfferExpired, enums.OfferEventTypes.SystemCancelledOffer].includes(offerEvent.eventType)) {
                  this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, offerEvent.offerId);
                } else
                  this.offerService.offerByStatusResponse.offerDataResponses.unshift(offer);

                this.getTotalOffersByStatus();
                this.cdr.detectChanges();
              }
            }
          } else {
            if ([enums.OfferEventTypes.BuyerAcceptedOffer, enums.OfferEventTypes.VendorAcceptedOffer,
            enums.OfferEventTypes.BuyerRejectedOffer, enums.OfferEventTypes.VendorRejectedOffer].includes(offerEvent.eventType)
              && this.offerService.selectedOfferStatusFilter?.status === enums.FullOfferStatusEnum.Pending) {
              const index = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId === offerEvent.offerId);
              if (this.offerService.offerByStatusResponse.offerDataResponses.length === 1) {
                this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);
                return;
              }
              if (index >= 0) {
                this.offerService.offerByStatusResponse.offerDataResponses.splice(index, 1);

                if ([enums.OfferEventTypes.BuyersOfferExpired, enums.OfferEventTypes.VendorsOfferExpired, enums.OfferEventTypes.SystemCancelledOffer].includes(offerEvent.eventType)) {
                  var allCounter = this.totalOffersByStatusResponse.find(x => x.status === enums.FullOfferStatusEnum.All);
                  this.defaultTitleDropdown = `All Offers (${allCounter})`
                  this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);
                }

              }
              this.getTotalOffersByStatus();
              return;
            }
            this.updateOrAddOfferToList(offerEvent.offerId);
            this.getTotalOffersByStatus();
          }
        },
        (err) => {
          console.log('Error trying to get offers summary: ', err);
        }
      )
    );

  }

  changeOfferStatusFilter(offerStatus: enums.FullOfferStatusEnum, offerId: string, initializing = false) {
    this.isLandingInSelectedOffer++;
    this.offerService.selectedOfferStatusFilter.status = offerStatus;
    this.paramOfferId = offerId;

    this.offersByStatusRequest = {
      pageSize: environment.offerListPageSize,
      pageNumber: 1,
      status: offerStatus,
    }
    if (offerId) {
      if (initializing) {
        this.getOffersByStatus(this.offersByStatusRequest, true, initializing);
        return;
      }
      this.getTotalOffersByStatus();
      this.getOffersByStatus(this.offersByStatusRequest, true);
    } else {
      this.getOffersByStatus(this.offersByStatusRequest, false);
    }
  }

  updateLastEventTypeInOfferList(offerId: string, lastEventType: enums.OfferEventTypes, lastEventTimestampUtc: string) {
    let listedSeletectedOffer = this.offerService.offerByStatusResponse.offerDataResponses.find(lsOffer => lsOffer.offerId === offerId);
    listedSeletectedOffer.lastEventType = lastEventType;
    listedSeletectedOffer.lastEventTimestampUtc = lastEventTimestampUtc;
  }

  newMessagesReceived(offerId, ownEvent: models.OfferEvent) {
    if (ownEvent && [enums.OfferEventTypes.VendorCounteroffered, enums.OfferEventTypes.BuyerCounteroffered].includes(ownEvent.eventType)) {
      this.removeCounterpartyPrior();
    }
    if (ownEvent != null) {
      this.updateLastEventTypeInOfferList(offerId, ownEvent.eventType, ownEvent.timestamp.toUTCString());

      var currentOfferEventMsg = this.eventMessages[this.eventMessages.length - 1];
      if (currentOfferEventMsg) {
        currentOfferEventMsg.instance.isCounterpartyPriorOffer = true;
      }

      this.fillOfferChat([ownEvent], true);
      return;
    }
    if (this.offerService.selectedOffer && offerId !== this.offerService.selectedOffer?.id)
      return;

    this.topService.loading = true;
    this.subscriptions.push(
      this.offerService.getOfferById(offerId)
        .subscribe(
          (offer: models.Offer) => {
            this.topService.loading = false;
            const offerEventsDiff = offer.offerEvents.filter((e) => this.offerService.selectedOffer.offerEvents.findIndex(e1 => e1.id === e.id) < 0);

            if (
              [enums.OfferEventTypes.VendorCounteroffered, enums.OfferEventTypes.BuyerCounteroffered].includes(offerEventsDiff[offerEventsDiff.length - 1]?.eventType) ||
              (
                [enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(offerEventsDiff[offerEventsDiff.length - 1]?.eventType) &&
                [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered]
                  .includes(this.offerService.getOfferEventMetadata(offerEventsDiff[offerEventsDiff.length - 1])?.mainEventType)
              )
            ) {
              this.removeCounterpartyPrior();
            }

            if (offerEventsDiff.length > 0) {
              const isTypingId = uuid.v4();
              const isTypingAttr = {
                isTyping: true,
                offerEvent: offer.offerEvents[offer.offerEvents.length - 1]
              }
              var dynamicComponent = new DynamicComponent(this.dynamicMessageContainer, OfferChatMessageComponent, isTypingAttr, isTypingId, this.cdr);
              dynamicComponent = this.dynamicComponentManagerService.addDynamicComponent(dynamicComponent);
              this.scrollOfferChat(200);
              const lastEventType = offer.offerEvents[offer.offerEvents.length - 1].eventType;
              const lastEventTimestampUtc = offer.offerEvents[offer.offerEvents.length - 1].timestamp.toString();
              this.updateLastEventTypeInOfferList(offerId, lastEventType, lastEventTimestampUtc);
              setTimeout(() => {
                this.byPassDelay = false;
                if ((this.offerService.selectedOffer && offer.id !== this.offerService.selectedOffer?.id) || !this.dynamicMessageContainer.length) {
                  return;
                }
                this.dynamicComponentManagerService.removeDynamicComponentsByViewContainerRef(this.dynamicMessageContainer);
                const previousEventMsg = this.eventMessages[this.eventMessages.length - 1];
                previousEventMsg.instance.isLastEvent = false;
                this.offerService.selectedOffer.expiresOn = offer.expiresOn;
                this.offerService.selectedOffer.version = offer.version;
                const isOwnEvent = false;

                // For Updated Offer
                var diff = offerEventsDiff.filter(oe => [enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(oe.eventType));
                if (diff.length) {
                  diff.forEach(offerEv => {
                    var offerInOfferList = this.offerService.offerByStatusResponse.offerDataResponses.find(o => o.offerId === this.offerService.selectedOffer.id);
                    if (offerInOfferList) {
                      offerInOfferList.quantity = offer.qty;
                    }

                    const parentEventId = this.offerService.getOfferEventMetadata(offerEv)?.parentEvent.eventId;
                    const pos = this.offerService.selectedOffer.offerEvents.findIndex(e => e.id === parentEventId);
                    if (pos >= 0) {
                      this.offerService.selectedOffer.offerEvents.splice(pos, 1);
                      const emIndex = this.eventMessages.findIndex(em => em.instance.offerEvent.id === parentEventId);
                      this.eventMessages.splice(emIndex, 1);
                      this.dynamicComponentManagerService.removeDynamicComponent(parentEventId);
                    } else {
                      // A multiple update may have occurred and the parent event id of the update is not drawn.
                      // Therefore I delete all drawn events that are not in the offer received from the server.
                      var idsToPersist = offer.offerEvents.map(oe => oe.id);
                      const eventMesaggesFiltered = this.eventMessages.filter(em => idsToPersist.includes(em.instance.offerEvent.id));
                      const filtered = this.offerService.selectedOffer.offerEvents = this.offerService.selectedOffer.offerEvents.filter(oe => idsToPersist.includes(oe.id));
                      const idsToRemove = this.eventMessages.filter(em => !idsToPersist.includes(em.instance.offerEvent.id)).map(x => x.instance.offerEvent.id);

                      idsToRemove.forEach(id => {
                        this.dynamicComponentManagerService.removeDynamicComponent(id);
                      });
                      this.eventMessages = eventMesaggesFiltered;
                      this.offerService.selectedOffer.offerEvents = filtered;
                    }
                  })
                }

                // For Accepted Previous Offer Event
                const oed = offerEventsDiff.find(oed => [enums.OfferEventTypes.BuyerAcceptedPreviousOffer, enums.OfferEventTypes.VendorAcceptedPreviousOffer].includes(oed.eventType));
                if (oed) {
                  this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);
                  var referenced = this.offerService.selectedOffer.offerEvents
                    .find(oe => oe.id === this.offerService.getOfferEventMetadata(oed)?.referenceEventId)
                  if (referenced) {
                    var eventMessage = this.eventMessages.find(em => em.instance.offerEvent.id === referenced.id);
                    eventMessage.instance.isCounterpartyPriorOffer = true;
                    this.offerService.selectedOffer.status = enums.FullOfferStatusEnum.Successful;
                    this.offerService.selectedOffer.offerEvents = offer.offerEvents;
                  }
                }


                this.fillOfferChat(offerEventsDiff, isOwnEvent);


              }, this.byPassDelay ? 1 : 5000);
            }
          },
          (err) => {
            console.log('Error trying to get offer by id ', err);
            this.topService.loading = false;
          }
        ));
  }

  removeCounterpartyPrior() {
    var counterpartyPriorOfferEventMsg = this.eventMessages.find(oe => oe.instance.isCounterpartyPriorOffer === true);
    if (counterpartyPriorOfferEventMsg) {
      counterpartyPriorOfferEventMsg.instance.isCounterpartyPriorOffer = false;
    }
  }

  onSelectOffer(offerId: string): void {
    this.isLandingInSelectedOffer += 1;
    this.selectOffer(offerId);
    const instanceId = sessionStorage.getItem('sessionId');
    this.location.replaceState(`${this.isBuyer ? '' : 'vendor/'}user/offers/${offerId}`);
  }

  counterpartyPriorOffer(isLastEvent, hideResponseButtons, events: models.OfferEvent[], offerEvent: models.OfferEvent): boolean {
    if (isLastEvent && !hideResponseButtons) {
      return false;
    }

    // The last event is mine and it is a counteroffer, so the penultimate one is someone else's previous event (if exist)
    const totalEvents = events.length;
    const lastEvent = events[totalEvents - 1];
    const penultimateEvent = totalEvents >= 2 ? events[totalEvents - 2] : null;

    const buyerPortalRoles = ['SUPER', 'SALES', 'BUYER'];
    const vendorPortalRoles = ['ADMIN', 'VENDORMGR', 'SELLER'];
    const userRole = sessionStorage.getItem('user_role');
    var metadata = this.offerService.getOfferEventMetadata(lastEvent)

    if (buyerPortalRoles.includes(userRole)) {
      if (lastEvent.eventType === enums.OfferEventTypes.BuyerCounteroffered ||
        (lastEvent.eventType === enums.OfferEventTypes.BuyerUpdatedOfferEvent && enums.OfferEventTypes.BuyerCounteroffered === metadata?.mainEventType)) {
        return penultimateEvent && offerEvent.id === penultimateEvent.id;
      }
    }

    if (vendorPortalRoles.includes(userRole)) {
      if (lastEvent.eventType === enums.OfferEventTypes.VendorCounteroffered ||
        (lastEvent.eventType === enums.OfferEventTypes.VendorUpdatedOfferEvent && enums.OfferEventTypes.VendorCounteroffered === metadata?.mainEventType)) {
        return penultimateEvent && offerEvent.id === penultimateEvent.id;
      }
    }

    return false;
  }

  fillOfferChat(events: models.OfferEvent[], isOwnEvent: boolean = false) {
    if (this.isLandingInSelectedOffer === 0 && !isOwnEvent) {
      this.offerService.selectedOffer.offerEvents = this.offerService.selectedOffer.offerEvents.concat(events);
    }
    this.isLandingInSelectedOffer = 0;

    this.dynamicComponentManagerService.removeDynamicComponentsByViewContainerRef(this.dynamicMessageContainer);

    events.forEach(eventMessage => {
      const metadata = this.offerService.getOfferEventMetadata(eventMessage);
      this.offerService.priceOptions = [];
      const id = isOwnEvent ? uuid.v4() : eventMessage.id;
      var isLastEvent = (isOwnEvent || this.offerService.selectedOffer.offerEvents.indexOf(eventMessage) === (this.offerService.selectedOffer.offerEvents.length - 1));
      if ([
        enums.OfferEventTypes.VendorRejectedOffer,
        enums.OfferEventTypes.BuyerRejectedOffer,
        enums.OfferEventTypes.BuyerCancelledOffer,
        enums.OfferEventTypes.VendorCancelledOffer,
        enums.OfferEventTypes.SystemCancelledOffer,
        enums.OfferEventTypes.BuyersOfferExpired,
        enums.OfferEventTypes.VendorsOfferExpired,
        enums.OfferEventTypes.OrderCreated
      ].includes(eventMessage.eventType)) {
        isLastEvent = true;
      }
      var hideResponseButtons;
      if (isLastEvent &&
        [enums.OfferEventTypes.OfferMade,
        enums.OfferEventTypes.VendorCounteroffered,
        enums.OfferEventTypes.BuyerCounteroffered,
        enums.OfferEventTypes.VendorUpdatedOfferEvent,
        enums.OfferEventTypes.BuyerUpdatedOfferEvent,
        ].includes(eventMessage.eventType)) {
        hideResponseButtons = this.toHideResponseButons(eventMessage.eventType, this.offerService.getOfferEventMetadata(eventMessage)?.isFinal);
      } else {
        hideResponseButtons = true;
      }

      var referenced = events.find(x => this.offerService.getOfferEventMetadata(x)?.referenceEventId === eventMessage.id);
      const isCounterpartyPriorOffer = this.counterpartyPriorOffer(isLastEvent, hideResponseButtons, events, eventMessage) || referenced;

      const attr = {
        isLastEvent: isLastEvent,
        offerEvent: eventMessage,
        offerItem: this.offerService.selectedOfferItem,
        metadata: metadata,
        totalPercentOff: this.offerService.percentOff(metadata?.effectivePrice, metadata?.price),
        displayCounterOfferForm: false,
        isBuyer: this.isBuyer,
        isVendor: this.isVendor,
        isSpecialRole: this.isSpecialRole,
        hideResponseButtons: hideResponseButtons,
        priceOptions: this.offerService.priceOptions,
        isCounterpartyPriorOffer: isCounterpartyPriorOffer
      };

      if (!this.dynamicMessageContainer) {
        this.cdr.detectChanges();
      }
      var container = isOwnEvent ? this.dynamicMessageContainer : this.eventMessageContainer;
      var dynamicComponent = new DynamicComponent(container, OfferChatMessageComponent, attr, id, this.cdr);
      dynamicComponent = this.dynamicComponentManagerService.addDynamicComponent(dynamicComponent);
      this.offerService.lastDynamicComponentId = id;

      if (!isOwnEvent) {
        this.eventMessages.push(dynamicComponent.componentRef);
        this.offerService.lastOwnCounterofferEvent = null;
      }

      if (!hideResponseButtons) {
        dynamicComponent.componentRef.instance.counterOffer.subscribe(event => this.counterOffer(event));
        dynamicComponent.componentRef.instance.backCounterOffer.subscribe(event => this.backCounterOffer(id));
      }

      dynamicComponent.componentRef.instance.editOffer.subscribe(event => this.editOffer(event));
      dynamicComponent.componentRef.instance.editedOffer.subscribe(event => this.editedOffer(event));
      dynamicComponent.componentRef.onDestroy(_ => dynamicComponent.componentRef.instance.editedOffer.unsubscribe());

      if (isOwnEvent) {
        this.eventMessages[this.eventMessages.length - 1].instance.isLastEvent = false;
        this.offerService.lastOwnCounterofferEvent = events[events.length - 1];
      }

      if (eventMessage.eventType === enums.OfferEventTypes.OrderCreated) {
        dynamicComponent.componentRef.instance.routeToOrderDetails.subscribe(event => this.goToOrderDetailPage(event));
      }
    });
    this.scrollOfferChat(300);

    const selectedOfferIndex = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId === this.offerService.selectedOffer.id);
    if (isOwnEvent) {
      this.offerService.offerByStatusResponse.offerDataResponses[selectedOfferIndex].lastEventType = events[events.length - 1].eventType;
    } else {
      this.offerService.offerByStatusResponse.offerDataResponses[selectedOfferIndex].lastEventType = this.offerService.selectedOffer.offerEvents[this.offerService.selectedOffer.offerEvents.length - 1].eventType;
    }
  }

  toHideResponseButons(eventType: enums.OfferEventTypes, isFinal: boolean): boolean {
    if (this.offerService.verifyIfOfferExpired(this.offerService.selectedOffer.expiresOn))
      return true;
    if (this.isBuyer && (eventType === enums.OfferEventTypes.VendorCounteroffered || eventType === enums.OfferEventTypes.VendorUpdatedOfferEvent))
      return false;
    if (this.isVendor && [enums.OfferEventTypes.OfferMade, enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.BuyerUpdatedOfferEvent].includes(eventType))
      return false;
    return true;
  }

  counterOffer(counterOffer: models.OfferEvent) {
    const metadata = this.offerService.getOfferEventMetadata(counterOffer);
    var effectivePrice = this.offerService.selectedOffer.effectivePrice;
    this.offerService.priceOptions = [];
    this.offerService.priceOptions = (metadata !== null && ([enums.OfferEventTypes.OfferMade,
    enums.OfferEventTypes.VendorCounteroffered,
    enums.OfferEventTypes.BuyerCounteroffered,
    enums.OfferEventTypes.VendorUpdatedOfferEvent,
    enums.OfferEventTypes.BuyerUpdatedOfferEvent,
    ].includes(counterOffer.eventType)))
      ? (this.isBuyer)
        ? this.offerService.counterOfferPrices(effectivePrice)
        : this.counterOfferCosts(this.offerService.selectedOffer.effectivePrice)
      : [];

    this.subscriptions.push(this.getOfferItemDetail(this.offerService.selectedOffer.vendorItemId)
      .subscribe(item => {
        this.offerService.selectedOfferItem.availability = item.availability;
        this.offerService.selectedOfferItem.listPrice = item.listPrice;
        this.offerService.selectedOfferItem.prices = item.prices;

        const attr: any = {
          offerEvent: counterOffer,
          metadata: metadata,
          priceOptions: this.offerService.priceOptions,
          displayCounterOfferForm: true,
          isBuyer: this.isBuyer,
          isVendor: this.isVendor,
          isLastEvent: true,
          item: item
        }
        this.eventMessages[this.eventMessages.length - 1].instance.isLastEvent = true;
        const id = uuid.v4();
        var dynamicComponent = new DynamicComponent(this.dynamicMessageContainer, OfferChatMessageComponent, attr, id, this.cdr);
        dynamicComponent = this.dynamicComponentManagerService.addDynamicComponent(dynamicComponent);
        dynamicComponent.componentRef.instance.backCounterOffer.subscribe(event => this.backCounterOffer(counterOffer));
        dynamicComponent.componentRef.instance.counteredOffer.subscribe(event => this.counteredOffer(event));
        dynamicComponent.componentRef.instance.editedOffer.subscribe(event => this.editedOffer(event));
        dynamicComponent.componentRef.onDestroy(_ =>  {
          dynamicComponent.componentRef.instance.counteredOffer.unsubscribe();
          dynamicComponent.componentRef.instance.editedOffer.unsubscribe();
        });
        this.scrollOfferChat(200, id);
      })
    );

  }

  backCounterOffer(event: models.OfferEvent) {
    const lastEventMsg = this.eventMessages[this.eventMessages.length - 1];
    lastEventMsg.instance.hideResponseButtons = false;
    lastEventMsg.instance.isLastEvent = true;
    this.dynamicComponentManagerService.removeDynamicComponentsByViewContainerRef(this.dynamicMessageContainer);
    if (this.topService.isMobile) {
      this.scrollOfferChat(200, event.id)
    }
  }

  counteredOffer(counteredOffer: models.CounterOfferResponse) {
    this.dynamicComponentManagerService.removeDynamicComponentsByViewContainerRef(this.dynamicMessageContainer);
    const selectedOfferIndex = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId === this.offerService.selectedOffer.id);

    if (this.isBuyer) {
      this.offerService.offerByStatusResponse.offerDataResponses[selectedOfferIndex].lastEventType = enums.OfferEventTypes.BuyerCounteroffered;
    }

    if (this.isVendor) {
      this.offerService.offerByStatusResponse.offerDataResponses[selectedOfferIndex].lastEventType = enums.OfferEventTypes.VendorCounteroffered;
    }

    this.offerService.selectedOffer.version = counteredOffer.version;
    const eventMessage = {
      id: counteredOffer.eventId,
      eventType: (this.isBuyer) ? enums.OfferEventTypes.BuyerCounteroffered : enums.OfferEventTypes.VendorCounteroffered,
      userId: sessionStorage.getItem('user_id'),
      version: 1,
      timestamp: new Date(),
      metadata: `{"EffectivePrice":${this.offerService.selectedOffer.effectivePrice},"GoodFor":${counteredOffer.goodFor},"Quantity":${counteredOffer.quantity},"Price":${counteredOffer.price},"IsFinal":${counteredOffer.isFinal}}`
    } as models.OfferEvent;
    this.offerService.selectedOffer.expiresOn = counteredOffer.expiresOn;
    this.newMessagesReceived(this.offerService.selectedOffer.id, eventMessage);
    // this.offerService.selectedOffer.offerEvents.push(eventMessage);
  }

  scrollOfferChat(delay: number, offerChatId = null) {
    setTimeout(() => {
      if (this.topService.isMobile) {
        if (offerChatId) {
          const offerChatComp = this.dynamicComponentManagerService.getDynamicComponent(offerChatId);
          offerChatComp.componentRef.instance.chatElementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
        }

        var offerChatComp = this.dynamicComponentManagerService.getLastDynamicComponentByViewContainerRef(this.dynamicMessageContainer);
        if (offerChatComp) {
          offerChatComp.componentRef.instance.chatElementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          return;
        }

        const offerEventsLenght = this.offerService.selectedOffer.offerEvents.length;
        const lastOfferEventId = this.offerService.selectedOffer.offerEvents[offerEventsLenght-1].id;
        offerChatComp = this.dynamicComponentManagerService.getDynamicComponent(lastOfferEventId);
        offerChatComp.componentRef.instance.chatElementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        this.eventMessageContainer?.element?.nativeElement?.parentElement?.scrollTo({
          top: this.eventMessageContainer.element.nativeElement.parentElement.scrollHeight,
          left: 0,
          behavior: "smooth"
        });
      }
    }, delay);
  }

  counterOfferCosts(cost: number, isEditingCounteroffer = false): models.PriceOptions[] {
    let result = new Array<models.PriceOptions>();
    const { percentVariation, percentPrecision, maxPercentage } = this.offerService.offersSummary.validationRules;

    const fixedPrecision = this.sharedService.countDecimals(percentVariation / 100);
    const pipeDigitInfo = `1.${percentPrecision}-${percentPrecision}`;
    const percentPipe = new PercentPipe('en_US');
    let i = parseFloat((percentVariation / 100).toFixed(fixedPrecision));

    var currentOfferPercentDiscount = 0;
    var previousOfferPercentDiscount = 0;

    var currentOfferQty = 0;
    var currentOfferEffectivePrice = 0;

    var previousOfferQty = 0;
    var previousEffectivePrice = 0

    var antepunultimateOfferQty = 0;
    var antepenultimateEffectivePrice = 0;

    var length = this.offerService.selectedOffer.offerEvents.length;

    if (isEditingCounteroffer && !this.offerService.lastOwnCounterofferEvent) {
      length--;
    }

    currentOfferQty = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 1].metadata, this.sharedService.toCamelCase).quantity;
    currentOfferEffectivePrice = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 1].metadata, this.sharedService.toCamelCase).effectivePrice;

    if (length >= 2) {
      previousOfferQty = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 2].metadata, this.sharedService.toCamelCase).quantity;
      previousEffectivePrice = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 2].metadata, this.sharedService.toCamelCase).effectivePrice;
    }

    if (length >= 3) {
      antepunultimateOfferQty = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 3].metadata, this.sharedService.toCamelCase).quantity;
      antepenultimateEffectivePrice = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 3].metadata, this.sharedService.toCamelCase).effectivePrice;
    }

    const currentOfferCost = JSON.parse(this.offerService.selectedOffer.offerEvents[length - 1].metadata, this.sharedService.toCamelCase).price;
    const previousOfferCost = length === 1
      ? JSON.parse(this.offerService.selectedOffer.offerEvents[0].metadata, this.sharedService.toCamelCase).price
      : JSON.parse(this.offerService.selectedOffer.offerEvents[length - 2].metadata, this.sharedService.toCamelCase).price;

    var currentOfferPercentDiscount = parseFloat((100 - (currentOfferCost * 100 / cost)).toFixed(2));
    var previousOfferPercentDiscount = parseFloat((100 - (previousOfferCost * 100 / cost)).toFixed(2));

    var discountAppliedInPrevious = (previousOfferQty !== antepunultimateOfferQty) && previousEffectivePrice != antepenultimateEffectivePrice;
    var discountAppliedInCurrent = (currentOfferQty !== previousOfferQty) && currentOfferEffectivePrice != previousEffectivePrice;

    while (i <= parseFloat((maxPercentage / 100).toFixed(fixedPrecision))) {
      const currentPrice = this.offerService.priceOff(cost, i);

      var disabled = previousOfferPercentDiscount == currentOfferPercentDiscount
        ? (i * 100) >= currentOfferPercentDiscount
        : !discountAppliedInPrevious && !discountAppliedInCurrent && (i * 100) <= previousOfferPercentDiscount ||
        (i * 100) >= currentOfferPercentDiscount;
      result.push({
        price: currentPrice,
        percent: '-'.concat(percentPipe.transform(i, pipeDigitInfo)),
        dropdownOptionDisabled: disabled
      } as models.PriceOptions);
      i = parseFloat((i + percentVariation / 100).toFixed(fixedPrecision));
    }

    return result;
  }

  acceptOffer() {
    if (this.topService.loading || this.offerService.disableCounterofferFormActions) {
      return;
    }

    if (!this.offerService.confirmAcceptanceSettings.show) {
      this.offerService.confirmAcceptanceSettings.show = true;
      return;
    }

    this.offerService.disableCounterofferFormActions = true;
    const selectedOfferId = this.offerService.selectedOffer.id;
    const length = this.offerService.selectedOffer.offerEvents.length;

    const acceptOfferPayload: models.AcceptOfferRequest = {
      offerId: selectedOfferId,
      instanceId: sessionStorage.getItem('sessionId'),
      effectivePrice: this.offerService.selectedOffer.effectivePrice,
      version: this.offerService.selectedOffer.version
    }

    this.topService.loading = true;
    this.subscriptions.push(
      this.offerService.acceptOffer(acceptOfferPayload).subscribe(
        (offerId: string) => {
          this.getTotalOffersByStatus();

          if(this.offerSelectedChanged(selectedOfferId)){
            this.getOffersByStatus(this.offersByStatusRequest);
            return;
          }

          this.offerService.selectedOffer.status = enums.FullOfferStatusEnum.Successful;
          this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);
          const eventMessage = {
            id: '',
            eventType: (this.isBuyer) ? enums.OfferEventTypes.BuyerAcceptedOffer : enums.OfferEventTypes.VendorAcceptedOffer,
            userId: sessionStorage.getItem('user_id'),
            version: 1,
            timestamp: moment.utc().toDate(),
          } as models.OfferEvent;

          this.newMessagesReceived(this.offerService.selectedOffer.id, eventMessage);

        },
        (err) => {
          this.topService.loading = false;
          this.offerService.disableCounterofferFormActions = false;
          if (err.status === this.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE || err.status === this.offerService.PRECONDITION_FAILED) {
            this.handleOfferError(err.error.errorCode);
          }
        })
    );
  }

  rejectOffer(isLastOffer: boolean) {
    if (this.topService.loading || this.offerService.disableCounterofferFormActions) {
      return;
    }

    if (!this.offerService.confirmRejectionSettings.show) {
      this.offerService.confirmRejectionSettings.bodyText = isLastOffer ? '' : 'You can send a counteroffer';
      this.offerService.confirmRejectionSettings.show = true;
      return;
    }

    this.offerService.disableCounterofferFormActions = true;
    this.topService.loading = true;
    const instanceId = sessionStorage.getItem('sessionId');

    const rejectOfferPayload: models.RejectOfferRequest = {
      instanceId: instanceId,
      version: this.offerService.selectedOffer.version
    };
    const selectedOfferId = this.offerService.selectedOffer.id;

    this.subscriptions.push(
      this.offerService.rejectOffer(this.offerService.selectedOffer.id, rejectOfferPayload).subscribe(
        (offerId: string) => {
          this.getTotalOffersByStatus();
          
          if(this.offerSelectedChanged(selectedOfferId)){
            this.getOffersByStatus(this.offersByStatusRequest);
            return;
          }

          this.offerService.selectedOffer.status = enums.FullOfferStatusEnum.Unsuccessful;
          this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);

          const eventMessage = {
            id: '',
            eventType: (this.isBuyer) ? enums.OfferEventTypes.BuyerRejectedOffer : enums.OfferEventTypes.VendorRejectedOffer,
            userId: sessionStorage.getItem('user_id'),
            version: 1,
            timestamp: moment.utc().toDate(),
          } as models.OfferEvent;
          this.topService.loading = false;
          this.newMessagesReceived(this.offerService.selectedOffer.id, eventMessage);
        },
        (err) => {
          console.log('Error trying to reject offer');
          this.topService.loading = false;
          this.offerService.disableCounterofferFormActions = false;
          if (err.status === this.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE || err.status === this.offerService.PRECONDITION_FAILED) {
            //Reject validation failure returns just error code
            this.handleOfferError(err.error);
          }
        }
      )
    );

  }

  goToOrderDetailPage(docEntry: string) {
    if (this.isBuyer) {
      this.ordersService.goToFullOrderPage(docEntry);
    } else if (this.isVendor) {
      this.router.navigate(['vendor/po-full-details', docEntry]);
    }
  }

  submitCounteroffer() {
    if (this.offerService.submitCounterofferReference.fn) {
      this.offerService.submitCounterofferReference.fn(this.offerService.submitCounterofferReference.scope);
    }
  }

  submitUpdateCounterOffer() {
    if (this.offerService.submitUpdateCounterofferReference.fn) {
      this.offerService.submitUpdateCounterofferReference.fn(this.offerService.submitUpdateCounterofferReference.scope);
    }
  }

  markOfferAsSeen(offerId: string): void {
    const index = this.offerService.offerByStatusResponse.offerDataResponses.findIndex(o => o.offerId === offerId);
    if (index >= 0) {
      this.offerService.offerByStatusResponse.offerDataResponses[index].unseenEventsCount = 0;
    }
  }

  handleOfferError(offerErrorCode: number) {
    this.offerService.offerErrorPopupSettings.headerText = OfferErrorCodeMap.ErrorCodeMap[offerErrorCode];
    this.offerService.offerErrorPopupSettings.show = true;
    this.onSelectOffer(this.offerService.selectedOffer.id);
  }

  acceptPreviousOffer(offerEvent: models.OfferEvent) {
    if (this.topService.loading) {
      return;
    }

    if (!this.offerService.confirmPriorAcceptanceSettings.show) {
      this.offerService.confirmPriorAcceptanceSettings.show = true;
      this.previousOfferEvent = offerEvent;
      return;
    }

    const index = this.offerService.selectedOffer.offerEvents?.findIndex(oe => oe.id === this.previousOfferEvent.id);
    if (index < 0) {
      return;
    }

    this.offerService.disableCounterofferFormActions = true;
    const selectedOfferId = this.offerService.selectedOffer.id;
    const acceptPreviousOfferPayload: models.AcceptPreviousOfferRequest = {
      instanceId: sessionStorage.getItem('sessionId'),
      effectivePrice: this.offerService.getOfferEventMetadata(this.previousOfferEvent)?.effectivePrice,
      referenceEventId: this.previousOfferEvent.id,
      version: this.offerService.selectedOffer.version
    }
    
    this.topService.loading = true;
    this.offerService.acceptPreviousOffer(acceptPreviousOfferPayload).subscribe(
      (response: models.AcceptPreviousOfferResponse) => {
        
        if(this.offerSelectedChanged(selectedOfferId)){
          this.getTotalOffersByStatus();
          this.getOffersByStatus(this.offersByStatusRequest);
          return;
        }
        this.offerService.selectedOffer.status = enums.FullOfferStatusEnum.Successful;
        const eventMessage = {
          eventId: '',
          eventType: (this.isBuyer) ? enums.OfferEventTypes.BuyerAcceptedPreviousOffer : enums.OfferEventTypes.VendorAcceptedPreviousOffer,
          offerId: this.offerService.selectedOffer.id,
          instanceId: sessionStorage.getItem('sessionId') + 'makeItDifferent',
          senderId: sessionStorage.getItem('user_id'),
        } as models.IOfferEventMessage;
        this.byPassDelay = true;
        this.handleNewOfferEventReceived(eventMessage);
      },
      (err) => {
        this.topService.loading = false;
        if ([this.offerService.PRECONDITION_FAILED, this.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE].includes(err.status)) {
          this.handleOfferError(err.error.errorCode);
        }
        console.log('Error trying to accept previous offer');
      }
    )
  }

  cancelOffer() {
    if (this.topService.loading) {
      return;
    }

    if (!this.offerService.confirmCancellationSettings.show) {
      this.offerService.confirmCancellationSettings.show = true;
      return;
    }

    const cancelOfferPayload: models.CancelOfferRequest = {
      instanceId: sessionStorage.getItem('sessionId'),
      version: this.offerService.selectedOffer.version
    }

    this.topService.loading = true;
    const canceledOfferId = this.offerService.selectedOffer.id;

    this.offerService.cancelOffer(cancelOfferPayload).subscribe(
      (response: string) => {
        this.getTotalOffersByStatus();

        if(this.offerSelectedChanged(canceledOfferId)){
          this.getOffersByStatus(this.offersByStatusRequest);
          return;
        }

        this.offerService.selectedOffer.status = enums.FullOfferStatusEnum.Unsuccessful;
        this.changeOfferStatusFilter(enums.FullOfferStatusEnum.All, null);

        const eventMessage = {
          id: '',
          eventType: (this.isBuyer) ? enums.OfferEventTypes.BuyerCancelledOffer : enums.OfferEventTypes.VendorCancelledOffer,
          userId: sessionStorage.getItem('user_id'),
          version: 1,
          timestamp: moment.utc().toDate(),
        } as models.OfferEvent;
        this.newMessagesReceived(this.offerService.selectedOffer.id, eventMessage);
      },
      (err) => {
        this.topService.loading = false;
        if ([this.offerService.PRECONDITION_FAILED, this.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE].includes(err.status)) {
          this.handleOfferError(err.error);
        }
        console.log('Error trying to cancel offer');
      }
    )
  }

  editOffer(offerEvent: models.OfferEvent) {
    const metadata = this.offerService.getOfferEventMetadata(offerEvent);
    var effectivePrice = this.offerService.selectedOffer.effectivePrice;
    this.offerService.priceOptions = [];

    if (offerEvent.eventType === enums.OfferEventTypes.OfferMade) {
      this.offerService.priceOptions = this.offerService.counterOfferPrices(effectivePrice, true);
      return;
    }

    var isEditingCounteroffer = true;
    if (this.offerService.selectedOffer.offerEvents.length == 1) {
      isEditingCounteroffer = false;
    }

    const fromMakeOffer = this.offerService.selectedOffer.offerEvents.length === 1;

    this.offerService.priceOptions = (metadata !== null && (
      [enums.OfferEventTypes.OfferMade,
      enums.OfferEventTypes.VendorCounteroffered,
      enums.OfferEventTypes.BuyerCounteroffered,
      enums.OfferEventTypes.VendorUpdatedOfferEvent,
      enums.OfferEventTypes.BuyerUpdatedOfferEvent,
      ].includes(offerEvent.eventType)))
      ? (this.isBuyer)
        ? this.offerService.counterOfferPrices(effectivePrice, fromMakeOffer, isEditingCounteroffer)
        : this.counterOfferCosts(effectivePrice, isEditingCounteroffer)
      : [];
  }

  editedOffer(updated: models.OfferEventUpdateResponse) {
    this.updateLastEventTypeInOfferList(
      this.offerService.selectedOffer.id,
      this.isBuyer ? enums.OfferEventTypes.BuyerUpdatedOfferEvent : enums.OfferEventTypes.VendorUpdatedOfferEvent,
      new Date().toUTCString());

    var offerInOfferList = this.offerService.offerByStatusResponse.offerDataResponses.find(o => o.offerId === this.offerService.selectedOffer.id);
    offerInOfferList.quantity = updated.quantity;

    this.offerService.selectedOffer.expiresOn = updated.expireOn;
    this.offerService.selectedOffer.version = updated.version;

    const index = this.offerService.selectedOffer.offerEvents.findIndex(oe => oe.id === updated.parentEvent.eventId);
    var offerEvent;

    if (index >= 0) {
      offerEvent = this.offerService.selectedOffer.offerEvents[index];
    } else {
      offerEvent = this.dynamicComponentManagerService.getDynamicComponent(this.offerService.lastDynamicComponentId).componentRef.instance.offerEvent;
    }

    const newParent: any = {};
    newParent.GoodFor = updated.parentEvent.goodFor;
    newParent.Quantity = updated.parentEvent.quantity;
    newParent.Price = updated.parentEvent.price;
    newParent.IsFinal = updated.parentEvent.isFinal;

    var metadata = JSON.parse(offerEvent.metadata);
    metadata.GoodFor = updated.goodFor;
    metadata.Quantity = updated.quantity;
    metadata.Price = updated.price;
    metadata.IsFinal = updated.isFinal;
    metadata.mainEventType = updated.mainEventType;
    metadata.ParentEvent = newParent;
    metadata.EffectivePrice = this.offerService.selectedOffer.effectivePrice;

    offerEvent.userId = sessionStorage.getItem('user_id');
    offerEvent.version = 1;
    offerEvent.timestamp = new Date();
    offerEvent.metadata = JSON.stringify(metadata);
    offerEvent.eventType = this.isBuyer ? enums.OfferEventTypes.BuyerUpdatedOfferEvent : enums.OfferEventTypes.VendorUpdatedOfferEvent;

    var eventMessage = this.eventMessages.find(em => em.instance.offerEvent.id === updated.parentEvent.eventId);
    if (eventMessage) {
      eventMessage.instance.metadata = this.offerService.getOfferEventMetadata(this.offerService.selectedOffer.offerEvents[index]);
      eventMessage.instance.totalPercentOff = this.offerService.percentOff(metadata.EffectivePrice, metadata?.Price);
      eventMessage.instance.offerEvent = this.offerService.selectedOffer.offerEvents[index];

      this.offerService.selectedOffer.offerEvents[index].id = updated.eventId;
    } else { // is a dynamic: Counteroffer
      const dynamicComponentRef = this.dynamicComponentManagerService.getDynamicComponent(this.offerService.lastDynamicComponentId).componentRef;
      dynamicComponentRef.instance.metadata = this.offerService.getOfferEventMetadata(offerEvent);
      dynamicComponentRef.instance.totalPercentOff = this.offerService.percentOff(dynamicComponentRef.instance.metadata.effectivePrice, dynamicComponentRef.instance.metadata.price);
      dynamicComponentRef.instance.offerEvent = offerEvent;
      offerEvent.id = updated.eventId;
    }

  }

  goToOffers() {
    const url = `${this.isBuyer ? '/user/offers' : 'vendor/user/offers'}`;
    if (this.router.url.match(/offers/)) {
      this.location.replaceState(url);
      this.offerService.selectedOffer = null;
      return;
    }
    this.router.navigate([url]);
  }

  offerSelectedChanged(id: string): boolean{
    return this.offerService.selectedOffer?.id !== id;
  }

}
